import { z } from 'zod'
import { FC } from 'react'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'

import { Button } from '@genie-fintech/ui/components'

import { callingCodes } from '$constants/country-data'
import InputPhone from '$fields/Phone'
import Phone from '$hook-fields/Phone'
import { requiredString } from '$hook-forms/general-validators'

import { container, footer } from './styles.css'

const schema = z.object({
  phone_code: requiredString,
  phone_no: requiredString.min(5, { message: 'At least 5 digits required' })
})

type TField = z.infer<typeof schema>

type Props = {
  current_phone_code?: string
  current_phone_no?: string
  loading?: boolean
  onCancel: VoidFunction
  onContinue: (values: TField) => void
}

const initialPhoneCode = `${callingCodes[0].code}`

const defaultValues = {
  phone_code: initialPhoneCode,
  phone_no: ''
}

const ChangePhoneRequestForm: FC<Props> = ({
  current_phone_code,
  current_phone_no,
  onCancel,
  onContinue,
  loading
}) => {
  const { control, handleSubmit, reset } = useForm<TField>({
    resolver: zodResolver(schema),
    defaultValues
  })

  const hasPhone = !!current_phone_code && !!current_phone_no

  return (
    <form className={container} onSubmit={handleSubmit(onContinue)}>
      {hasPhone && (
        <InputPhone
          callingCodes={callingCodes}
          label="Current Phone Number"
          required
          disabled
          callingCodeProps={{ value: current_phone_code }}
          inputProps={{ value: current_phone_no }}
        />
      )}

      <Phone
        control={control}
        callingCodes={callingCodes}
        name="phone_no"
        name2="phone_code"
        label={hasPhone ? 'New Phone Number' : 'Phone Number'}
        required
        inputProps={{ placeholder: 'XXXX XXXX XXXX' }}
      />

      <footer className={footer}>
        <Button
          onClick={() => {
            reset(defaultValues)
            onCancel()
          }}
          disabled={loading}
          styleVariants={{ kind: 'neutral', size: 'small', type: 'outlined' }}
        >
          Cancel
        </Button>

        <Button
          type="submit"
          styleVariants={{ size: 'small' }}
          disabled={loading}
        >
          Continue
        </Button>
      </footer>
    </form>
  )
}

export default ChangePhoneRequestForm
